// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
.kt-header {
  max-height: 130px !important;
  background: #1e2732 !important;
}

.kt-header__bottom {
  background: none !important;
}

.kt-header__bottom .kt-menu__link * {
  color: #FFF !important;
}

.kt-header__bottom li.kt-menu__item--active .kt-menu__link {
  background: #72c4e6 !important;
}

.kt-header__bottom li.kt-menu__item--active .kt-menu__link * {
  color: #860000 !important;
}

.kt-header__brand-nav #dropdown-toggle-top {
  background: none !important;
  color: #FFF;
  font-weight: bold;
}

.kt-menu__subnav .kt-menu__link {
  background: #FFF !important;
}

.kt-menu__subnav .kt-menu__link .kt-menu__link-text {
  color: #0c2163 !important;
}

#kt_quick_search_inline .kt-quick-search__form .input-group {
  background: none !important;
}

#kt_quick_search_inline .kt-quick-search__form .input-group .kt-quick-search__input {
  color: #FFF !important;
}

.kt-header__brand-logo-default {
  width: 140px !important;
}

.btn-grey {
  background: #f3f3f3 !important;
  border: solid 1px #e1e1e1 !important;
}
.selectedMenu {
  background: rgba(203, 0, 0, 0.92);
}
.selectedMenu * {
  font-weight: bold !important;
}

.aside-menu {
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
}

.selectedMenu * {
  color: #FFF !important;
}

.form-control
{
  border: solid 1px #999999 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -khtml-border-radius: 0 !important;
  border-radius: 0 !important;
}

.hidden {
  display: none !important;
}

.field-value.form-control {
  border: 0px #FFFFFF !important;
  color: #000000 !important;
}

#kt_scrolltop,
.kt-scrolltop {
  bottom: 95px !important;
  right: 35px !important;
  /**/
}

.table-condensed, .table-condensed tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.page-title {
  color: #c60027;
  font-weight: normal;
  margin-bottom: 20px;
}

.origination-nav-top {
  font-size: 32px;
  margin-top: -10px !important;
}

.origination-nav-arrow {
  color: #333;
}

.origination-page-container {
  background: #FFF;
}
.origination-page-content {
  padding: 5%;
  padding-top: 3%;
}

.origination-summary-right {
  padding: 20px;
  background: #f9fbe7;
}

.origination-summary-item {
  margin-bottom: 15px;
  color: #000;
}

.origination-summary-item .origination-summary-item-title {
  font-weight: bold;
  font-size: 16px;
}

.origination-summary-item .origination-summary-item-text {
  font-weight: normal;
  font-size: 14px;
}

.origination-nav-footer {
  margin-top: 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  border-top: solid 1px #dfdfdf;
}

.currency-input-field-container {
  border: solid 1px #ddd;
  margin-bottom: 8px;
}

.currency-input-field-container .field-label {
  background: #fafafa;
  padding: 10px;
  text-align: right;
  font-size: 16px;
  padding-top: 14px;
}

.currency-input-field-container .field-input-container {
  padding:10px;
}

.currency-input-field-container .field-value {
  padding: 0px;
  height: 30px;
  font-size: 16px;
  border: none !important;
}

.money-input-field {
  text-align: right;
}

.currency-info-field-container {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 125%;
}

.currency-info-field-container .field-label {
  font-size: 16px;
  color: #151584;
}

.currency-info-field-container .field-value {
  color: #6b14be;
  text-align: left;
}

.currency-info-field-container .negative {
  color: #e60909;
}

.input-currency-label {
  font-size: 140%;
}

.list-item-input-form {
  padding: 8%;
  padding-bottom: 6%;
  padding-top: 5%;
  width: 85%;
  border: dashed 2px #50b3fa;
  margin: 15px;
  margin-left: 0px;
}

.contractor-list-item {
  padding: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
  border: dashed 2px #50b3fa;
  margin: 15px;
  margin-left: 0px;
}

.origination-toolbar-controls {
  color: #282727;
  font-size: 80%;
  text-transform: uppercase;
}

.origination-toolbar-controls i {
  font-size: 250%;
}

.origination-toolbar-controls.save-button i {
  color: #068fce;
}

.origination-toolbar-controls.finish-button i {
  color: #80c708;
}

.origination-toolbar-controls.reset-button i {
  color: #c70851;
}

div.field-group {
  margin-bottom: 25px;
}

.text-left {
  text-align: left !important;
}

.text-left {
  text-align: right !important;
}

.text-black {
  color: #000 !important;
}

.text-blue {
  color: #0c2163 !important;
}

.text-green {
  color: #399606 !important;
}

.strong-summary * {
  font-weight: bold;
}

.strong-label {
  font-weight: bold !important;
}

.no-border {
  border: none !important;
}

.no-outline {
  outline: none !important;
}

.spin-loader {
  line-height: 3;
  font-size: 150%;
  padding: 10%;
  text-align: center;
}

.client-breadcrumbs {
  padding-bottom: 15px;
  border-bottom: solid 2px #FFFFFF;
  font-size: 125%;
  margin-bottom: 15px;
}

.client-breadcrumbs .client-link {
  font-weight: bold;
}

.client-breadcrumbs .back-link {
  color: #131313;
}

.side-menu-row {
  padding-left: 30px;
  padding-top: 15px;
}

.asset-list-title {
  padding: 10px;
  background: rgba(82, 169, 230, 0.19);
  display: block;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.print-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: red;
  z-index: 100000;
}

.push-down {
  margin-top: 10px;
}

.fc-blue {
  color: #5989ff;
}

.text-fc-blue {
  color: #5989ff !important;
}
@media print {
  input {
    border: none !important;
  }
}

.border-b {
  border-bottom: solid 1px #999999 !important;
}

.clickable {
  cursor: pointer !important;
}

.lead-tiles,
.lead-tiles li {
  list-style: none;
}

.lead-tiles {
  margin-left: -40px;
}

.lead-tiles li {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 5%;
  width: 25%;
  padding: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 150%;
  color: #ffffff !important;
  float: left;
  text-align: center;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px;
  font-weight: bold;
}

.lead-tiles li a {
  color: #ffffff !important;
}

.disabled-menu {
  background: #cbcecf !important;
}

.tile-icon {
  font-size: 400% !important;
}

.account-menu {
  background: #007cdb;
}

.sales-menu {
  background: #a7ac09;
}

.user-menu {
  background: #c500db;
}

.loan-menu {
  background: #db9200;
}

.lead-tiles li:hover {
  backgroud: #fff !important;
}

.chip-status-approve {
  background: #308800 !important;
  color: #FFF !important;
}

.chip-status-reject {
  background: #730000 !important;
  color: #FFF !important;
}

.chip-status-pending {
  background: #883b00 !important;
  color: #FFF !important;
}

.chip-status-approved {
  background: #6b8800 !important;
  color: #FFF !important;
}

.chip-status-rejected {
  background: #6d0303 !important;
  color: #FFF !important;
}

.chip-status-referred {
  background: #006d88 !important;
  color: #FFF !important;
}

.max-height-300 {
  max-height: 300px !important;
  overflow-y: hidden;
}

.max-height-300:hover {
  overflow-y: auto;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-8 {
  margin-top: 8px;
}

.p-40 {
  padding: 40px
}

.filters-dropdown .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-toggle:after {
  content: none !important;
}